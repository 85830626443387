import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import "./dashboard.sass";
import Snowflake from "./icons/dashboard/Snowflake";
import RiverFlow from "./icons/dashboard/RiverFlow";
import WaterGlass from "./icons/dashboard/WaterGlass";
import Boat from "./icons/dashboard/Boat";
import WaterDrop from "./icons/dashboard/WaterDrop";
import Lightning from "./icons/dashboard/Lightning";
import RiverFlowDial from "./icons/dashboard/RiverFlowDial";
import MoreOptions from "./icons/dashboard/MoreOptions";
import Close from "./icons/dashboard/Close";
import fetch from "node-fetch";
import { remark } from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";

function numberWithCommas(x) {


  // consider decimal places
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function setDate() {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;
  return today;
}

const Dashboard = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riverflowsRenoMin: props.dashboard.riverflows.reno.min,
      riverflowsRenoMax: props.dashboard.riverflows.reno.max,
      lakeTahoeMax: props.dashboard.lakeTahoeLevel.max,
      lakeTahoeMin: props.dashboard.lakeTahoeLevel.min,
    };
  }

  showBackOfCard = (cardBack) => {
    if (typeof document === "object") {
      document
        .getElementById("dashboardCardContainer")
        .classList.add("is-hidden");
      document.getElementById(cardBack).classList.add("is-active");
      document.getElementById("dashboardCardBack").classList.add("is-active");
    }
  };

  showFrontOfCards = (cardBack) => {
    if (typeof document === "object") {
      document.getElementById(cardBack).classList.remove("is-active");
      document
        .getElementById("dashboardCardContainer")
        .classList.remove("is-hidden");
      document
        .getElementById("dashboardCardBack")
        .classList.remove("is-active");
    }
  };

  componentDidMount() {
    const { dashboard } = this.props;

    // const proxyUrl = "https://fathomless-inlet-92831.herokuapp.com/";
    // const proxyUrl = "";
    // const waterYearUrl = "https://wrcc.dri.edu/snotelanom/snotelbasin";
    // Possible SNOTEL replacement
    const waterYearUrl = "https://wcc.sc.egov.usda.gov/reports/UpdateReport.html?report=California/Nevada";

    const waterYearSearchString = `Basin Index (%)`;
    const waterYearValueIndexTahoe = 1;
    const waterYearValueIndexTruckee = 2;
    const waterYearIdTahoe = "waterYearValueTahoe";
    const waterYearIdTruckee = "waterYearValueTruckee";

    const riverflowIdReno = "riverflowReno";
    const riverflowDialReno = ".reno .riverflow-gauge";

    const riverflowIdFarad = "riverflowFarad";
    const riverflowDialFarad = ".farad .riverflow-gauge";

    this.setRiverflowDial(
      ".reno .riverflow-gauge",
      dashboard.riverflows.reno.value,
      "reno"
    );
    this.setRiverflowDial(
      ".farad .riverflow-gauge",
      dashboard.riverflows.farad.value,
      "farad"
    );

    if (!dashboard.waterYear.override) {
      this.setWaterYearData(
        waterYearUrl,
        waterYearSearchString,
        waterYearValueIndexTahoe,
        waterYearIdTahoe
      );
      this.setWaterYearData(
        waterYearUrl,
        waterYearSearchString,
        waterYearValueIndexTruckee,
        waterYearIdTruckee
      );
    }

    if (!dashboard.riverflows.override) {
      const riverflowUrlReno =
        "https://waterservices.usgs.gov/nwis/iv/?sites=10348000&period=P1D&format=json";
      // source: https://waterdata.usgs.gov/nv/nwis/uv/?site_no=10348000
      this.setRiverflowData(
        riverflowUrlReno,
        riverflowIdReno,
        riverflowDialReno,
        "reno"
      );
      const riverflowUrlFarad =
        "https://waterservices.usgs.gov/nwis/iv/?sites=10346000&period=P1D&format=json";
      // source: https://waterdata.usgs.gov/nv/nwis/uv/?site_no=10346000
      this.setRiverflowData(
        riverflowUrlFarad,
        riverflowIdFarad,
        riverflowDialFarad,
        "farad"
      );
    }

    if (!dashboard.upstreamWaterStorage.override) {
      // setUpstreamWaterStorage()
      setDate("upstreamWaterStorageDate");
    }

    if (!dashboard.waterYear.override) {
      setDate("waterYearDate");
    }
  }

  componentWillUnmount() { }

  setWaterYearData(url, searchString, valueIndex, id) {
    if (typeof document === "object") {
      fetch(url)
        .then((resp) => {
          if (resp.ok) {
            return resp;
          }
          throw new Error("Something went wrong");
        })
        .then((resp) => {
          return resp.text();
        })
        .then((text) => {
          let basinArray = text.split(searchString);
          basinArray = basinArray[valueIndex].split(`<span`)
          basinArray = basinArray[0].split(' ');
          const basinValue = basinArray ? basinArray[basinArray.length - 1] : null
          let element = document.getElementById(id);
          if (element && basinValue) {
            element.innerHTML = basinValue + `<span class="card-sup is-size-7">%</span>`;
            element.classList.add("is-set");
            element.style.marginTop = "1rem";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  setRiverflowData(url, id, dial, location) {
    let me = this;

    if (typeof document === "object") {
      fetch(url)
        .then((response) => response.json())
        .then((commits) => commits)
        .then(function (resp) {
          // To-do: loop through time series and find the streamflow value (in case data structure changes again)
          let valArr = resp.value.timeSeries[location === "farad" ? 1 : 0].values[0].value;
          let usgsValue = valArr[valArr.length - 1].value;
          if (usgsValue) {
            let span = document.getElementById(id);
            if (span) {
              span.textContent = numberWithCommas(usgsValue);
              span.classList.add("is-set");
            }

            me.setRiverflowDial(dial, usgsValue, location);

            return Number(usgsValue);
          }
        });
    }
  }

  setRiverflowDial(dialId, value, location) {
    let riverMin;
    let riverMax;

    if (location === "reno") {
      riverMin = this.state.riverflowsRenoMin;
      riverMax = this.state.riverflowsRenoMax;
    } else {
      riverMin = this.state.riverflowsRenoMin;
      riverMax = this.state.riverflowsRenoMax;
    }

    // get the dial
    if (typeof document === "object") {
      let dialContainer = document.querySelectorAll(dialId);
      dialContainer = Array.prototype.slice.call(dialContainer);

      // console.log(value)

      let riverflowPercent = ((value - riverMin) / (riverMax - riverMin)) * 100;
      let riverflowRotateZ = riverflowPercent * 1.8 - 90;

      if (riverflowRotateZ < -90) {
        riverflowRotateZ = -90;
      } else if (riverflowRotateZ > 90) {
        riverflowRotateZ = 90;
      }

      dialContainer.forEach(function (dial) {
        dial.style.transform = `rotateZ(${riverflowRotateZ.toString()}deg)`;
      });
    }
  }

  setLakeTahoeLevel(lakeTahoeDelta) {
    if (lakeTahoeDelta) {
      const lakeTahoeLevel = 6220 + Number(lakeTahoeDelta);

      return numberWithCommas(lakeTahoeLevel);
    }
  }

  setLakeTahoePercentage(lakeTahoeDelta) {
    let lakeTahoePercentage = 77;
    if (this.state.lakeTahoeMax && this.state.lakeTahoeMin) {
      const lakeTahoeLevel = 6220 + Number(lakeTahoeDelta);

      if (lakeTahoeLevel) {
        lakeTahoePercentage = Math.round(
          ((lakeTahoeLevel - this.state.lakeTahoeMin) /
            (this.state.lakeTahoeMax - this.state.lakeTahoeMin)) *
          100
        );
        if (lakeTahoePercentage < 0) {
          lakeTahoePercentage = 0;
        } else if (lakeTahoePercentage > 100) {
          lakeTahoePercentage = 100;
        }

        if (typeof document === "object") {
          // set value height
          let lakeTahoePercentageContainer = document.getElementById(
            "percentageBarValue"
          );
          if (lakeTahoePercentageContainer) {
            let percentageHeight = lakeTahoePercentage - 10;
            if (percentageHeight < 10) {
              percentageHeight = 0;
            }
            if (percentageHeight > 82) {
              percentageHeight = 82;
            }
            lakeTahoePercentageContainer.style.bottom = percentageHeight + "%";
          }

          // set bar height
          let lakeTahoePercentageBar = document.getElementById(
            "lakeTahoePercentageBar"
          );
          if (lakeTahoePercentageBar) {
            lakeTahoePercentageBar.style.height = lakeTahoePercentage + "%";
          }
        }
      }
    }
    return lakeTahoePercentage;
  }

  setLakeTahoeOverrideData(givenLakeTahoePercentage) {
    if (typeof document === "object") {
      // set value height
      let lakeTahoePercentageContainer = document.getElementById(
        "percentageBarValue"
      );
      if (lakeTahoePercentageContainer) {
        let percentageHeight = givenLakeTahoePercentage - 10;
        if (percentageHeight < 10) {
          percentageHeight = 0;
        }
        if (percentageHeight > 82) {
          percentageHeight = 82;
        }
        lakeTahoePercentageContainer.style.bottom = percentageHeight + "%";
      }

      // set bar height
      let lakeTahoePercentageBar = document.getElementById(
        "lakeTahoePercentageBar"
      );
      if (lakeTahoePercentageBar) {
        lakeTahoePercentageBar.style.height = givenLakeTahoePercentage + "%";
      }
    }
    return givenLakeTahoePercentage;
  }

  setTmwaData(url, valueId, updateId, digits) {
    fetch(url)
      .then((response) => response.json())
      .then(function (resp) {
        let valueContainer = document.getElementById(valueId);
        const value = resp.Value?.Value?.Value || resp.Value?.Value || resp.Value;

        if (valueContainer && value) {
          valueContainer.textContent = numberWithCommas(
            value.toFixed(digits)
          );
        }

        let updateContainer = document.getElementById(updateId);
        if (updateContainer) {
          updateContainer.textContent = "";
        }
      });
  }

  render() {
    const { dashboard, waterStorage, riverflow, lakeTahoe, tmwa } = this.props;
    return (
      <section className="section dashboard">
        <div className="box-light pt-0">
          <h2
            className="has-text-centered mb-5 is-size-4 has-text-weight-normal has-text-white pt-5"
            id="dashboard"
          >
            {dashboard.heading}
          </h2>
          <div className="is-relative">
            <div
              className="columns is-multiline dashboard-card-container mb-0"
              id="dashboardCardContainer"
            >
              {/* waterYear card */}
              <div className="column is-one-third-widescreen is-half">
                <Link
                  to="#dashboard"
                  className={`more-options-link`}
                  data-target="waterYearBack"
                  onClick={() => this.showBackOfCard("waterYearBack")}
                  onKeyDown={() => this.showBackOfCard("waterYearBack")}
                  tabIndex={0}
                  aria-label="More information"
                >
                  <div className="card" id="waterYearCard">
                    <div className="card-header">
                      <div className="icon card-header-icon">
                        <Snowflake />
                      </div>
                      <h3 className="card-header-title">
                        {dashboard.waterYear.heading}
                      </h3>
                      <div className="icon card-header-icon card-header-options">
                        <MoreOptions />
                      </div>
                    </div>
                    <div className="card-content">
                      <p className="mb-0">{dashboard.waterYear.pre}</p>
                      <div className="columns is-gapless mb-3 is-mobile">
                        <div className="column is-half">
                          <div>
                            <div
                              className={`is-flex is-flex-wrap-wrap is-justify-content-center	is-align-content-end ${dashboard.waterYear.override ? "is-set" : ""
                                } card-value`}
                              id="waterYearValueTahoe"
                            >

                              <p className="is-size-7 mb-1 w-100 is-flex-shrink-0">{!dashboard.waterYear.override ? "Updating..." : ""}</p>

                             <span>{dashboard.waterYear.percentage1.value}</span><span className="card-sup is-size-7">%</span>

                            </div>

                          </div>
                          <p>{dashboard.waterYear.percentage1.label}</p>
                        </div>
                        <div className="column is-half">
                          <div>
                            <div
                              className={`is-flex is-flex-wrap-wrap is-justify-content-center	is-align-content-end		 ${dashboard.waterYear.override ? "is-set" : ""
                                } card-value`}
                              id="waterYearValueTruckee"
                            >

                              <p className="is-size-7 mb-1 w-100">{!dashboard.waterYear.override ? "Updating..." : ""}</p>

                              <span>{dashboard.waterYear.percentage2.value}</span><span className="card-sup is-size-7">%</span>

                            </div>

                          </div>
                          <p>{dashboard.waterYear.percentage2.label}</p>
                        </div>
                      </div>
                      <p>
                        {dashboard.waterYear.post}
                        {dashboard.waterYear.override
                          ? dashboard.waterYear.date
                          : setDate()}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>

              {/* River Flows card */}
              <div className="column is-one-third-widescreen is-half">
                <Link
                  to="#dashboard"
                  className={`more-options-link`}
                  data-target="riverflowBack"
                  onClick={() => this.showBackOfCard("riverflowBack")}
                  onKeyDown={() => this.showBackOfCard("riverflowBack")}
                  tabIndex={0}
                  aria-label="More information"
                >
                  <div className="card" id="riverflowCard">
                    <div className="card-header">
                      <div className="icon card-header-icon">
                        <RiverFlow />
                      </div>
                      <h3 className="card-header-title">
                        {dashboard.riverflows.heading}
                      </h3>
                      <div className="icon card-header-icon card-header-options">
                        <MoreOptions />
                      </div>
                    </div>
                    <div className="card-content">
                      <div className="columns is-gapless mb-3 is-mobile">
                        <div className="column is-half reno">
                          <p className="mb-0">
                            {dashboard.riverflows.reno.label}
                          </p>
                          <div>
                            <RiverFlowDial />
                          </div>
                          <div>
                            <span
                              className={`card-value card-value-sm riverflow-value ${dashboard.riverflows.override || !riverflow
                                ? "is-set"
                                : ""
                                }`}
                              id="riverflowReno"
                            >
                              {dashboard.riverflows.override || !riverflow ? (
                                numberWithCommas(
                                  dashboard.riverflows.reno.value
                                )
                              ) : (
                                <div>
                                  <p className="is-size-6 mb-1">Updating...</p>
                                  <p>{numberWithCommas(riverflow.reno)}</p>
                                </div>
                              )}
                            </span>
                          </div>
                          <p>{dashboard.riverflows.reno.units}</p>
                        </div>
                        <div className="column is-half farad">
                          <p className="mb-0">
                            {dashboard.riverflows.farad.label}
                          </p>
                          <div>
                            <RiverFlowDial />
                          </div>
                          <div>
                            <span
                              className={`card-value card-value-sm riverflow-value ${dashboard.riverflows.override ? "is-set" : ""
                                }`}
                              id="riverflowFarad"
                            >
                              {dashboard.riverflows.override || !riverflow ? (
                                numberWithCommas(
                                  dashboard.riverflows.farad.value
                                )
                              ) : (
                                <div>
                                  <p className="is-size-6 mb-1">Updating...</p>
                                  <p>{numberWithCommas(riverflow.farad)}</p>
                                </div>
                              )}
                            </span>
                          </div>
                          <p>{dashboard.riverflows.farad.units}</p>
                        </div>
                      </div>
                      <p>
                        <span
                          className="riverflows-legend ml-0"
                          style={{ backgroundColor: "white" }}
                        ></span>{" "}
                        Low
                        <span
                          className="riverflows-legend"
                          style={{ backgroundColor: "#29ABE2" }}
                        ></span>{" "}
                        Medium
                        <span
                          className="riverflows-legend"
                          style={{ backgroundColor: "#8BC440" }}
                        ></span>{" "}
                        High
                      </p>
                    </div>
                  </div>
                </Link>
              </div>

              {/* Drinking Water Storage card */}
              <div className="column is-one-third-widescreen is-half">
                <Link
                  to="#dashboard"
                  className={`more-options-link`}
                  data-target="drinkingWaterStorageBack"
                  onClick={() =>
                    this.showBackOfCard("drinkingWaterStorageBack")
                  }
                  onKeyDown={() =>
                    this.showBackOfCard("drinkingWaterStorageBack")
                  }
                  tabIndex={0}
                  aria-label="More information"
                >
                  <div className="card" id="drinkingWaterStorageCard">
                    <div className="card-header">
                      <div className="icon card-header-icon">
                        <WaterGlass />
                      </div>
                      <h3 className="card-header-title">
                        {dashboard.upstreamWaterStorage.heading}
                      </h3>
                      <div className="icon card-header-icon card-header-options">
                        <MoreOptions />
                      </div>
                    </div>
                    <div className="card-content">
                      <p>{dashboard.upstreamWaterStorage.pre}</p>
                      <div>
                        <span className="card-value" id="upstreamWaterStorage">
                          {dashboard.upstreamWaterStorage.override ||
                            !waterStorage ||
                            typeof waterStorage.totalStorage == "undefined" ||
                            !waterStorage.totalStorage
                            ? numberWithCommas(
                              dashboard.upstreamWaterStorage.value
                            )
                            : numberWithCommas(waterStorage.totalStorage)}
                        </span>
                        <span className="card-sup">
                          {dashboard.upstreamWaterStorage.units}
                        </span>
                      </div>
                      <p>
                        {dashboard.upstreamWaterStorage.post}{" "}
                        <span id="upstreamWaterStorageDate">
                          {dashboard.upstreamWaterStorage.override ||
                            !waterStorage ||
                            typeof waterStorage.totalStorage == "undefined" ||
                            !waterStorage.totalStorage
                            ? dashboard.upstreamWaterStorage.date
                            : setDate()}
                        </span>
                      </p>
                    </div>
                  </div>
                </Link>
              </div>

              {/* Lake Tahoe Level card */}
              <div className="column is-one-third-widescreen is-half">
                <Link
                  to="#dashboard"
                  className={`more-options-link`}
                  data-target="lakeTahoeLevelBack"
                  onClick={() => this.showBackOfCard("lakeTahoeLevelBack")}
                  onKeyDown={() => this.showBackOfCard("lakeTahoeLevelBack")}
                  tabIndex={0}
                  aria-label="More information"
                >
                  <div className="card" id="lakeTahoeLevelCard">
                    <div className="card-header">
                      <div className="icon card-header-icon">
                        <Boat />
                      </div>
                      <h3 className="card-header-title">
                        {dashboard.lakeTahoeLevel.heading}
                      </h3>
                      <div className="icon card-header-icon card-header-options">
                        <MoreOptions />
                      </div>
                    </div>
                    <div className="card-content">
                      <div className="columns mt-1 is-mobile flex-grow h-100 is-gapless mb-2">
                        <div className="column is-2">
                          <div className="percentage-bar">
                            <div>
                              {numberWithCommas(dashboard.lakeTahoeLevel.max)}
                              {dashboard.lakeTahoeLevel.units}
                            </div>
                            <div className="percentage-bar-bar">
                              <span className="percentage-bar-background"></span>
                              <span
                                className="percentage-bar-value-background"
                                id="lakeTahoePercentageBar"
                              ></span>
                              <span
                                id="percentageBarValue"
                                className="percentage-bar-value is-size-4-mobile"
                              >
                                <span
                                  id="lakeTahoePercentage"
                                  className="percentage-bar-value-number"
                                >
                                  {dashboard.lakeTahoeLevel.override ||
                                    !lakeTahoe
                                    ? this.setLakeTahoeOverrideData(
                                      dashboard.lakeTahoeLevel.percentage
                                    )
                                    : this.setLakeTahoePercentage(
                                      lakeTahoe.level
                                    )}
                                </span>
                                <span className="percentage-bar-value-percent">
                                  %
                                </span>
                              </span>
                            </div>
                            <div>
                              {numberWithCommas(dashboard.lakeTahoeLevel.min)}
                              {dashboard.lakeTahoeLevel.units}
                            </div>
                          </div>
                        </div>
                        <div
                          className="column is-flex align-items-center justify-content-center mx-2"
                          style={{ marginBottom: "0.8rem" }}
                        >
                          <span className="card-value card-value-sm card-value-fullhd">
                            {dashboard.lakeTahoeLevel.override || !lakeTahoe
                              ? dashboard.lakeTahoeLevel.value
                              : this.setLakeTahoeLevel(lakeTahoe.level)}
                            {dashboard.lakeTahoeLevel.units}
                          </span>
                        </div>
                      </div>
                      <p
                        style={{
                          marginLeft: "-0.25rem",
                          marginRight: "-0.25rem",
                        }}
                      >
                        {dashboard.lakeTahoeLevel.label}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>

              {/* Water Treatment Plant Production card */}
              <div className="column is-one-third-widescreen is-half">
                <Link
                  to="#dashboard"
                  className={`more-options-link`}
                  data-target="waterTreatmentPlantProductionBack"
                  onClick={() =>
                    this.showBackOfCard("waterTreatmentPlantProductionBack")
                  }
                  onKeyDown={() =>
                    this.showBackOfCard("waterTreatmentPlantProductionBack")
                  }
                  tabIndex={0}
                  aria-label="More information"
                >
                  <div className="card" id="waterTreatmentPlantProductionCard">
                    <div className="card-header">
                      <div className="icon card-header-icon">
                        <WaterDrop />
                      </div>
                      <h3 className="card-header-title water-treatment-heading">
                        {dashboard.waterTreatmentPlantProduction.heading}
                      </h3>
                      <div className="icon card-header-icon card-header-options">
                        <MoreOptions />
                      </div>
                    </div>
                    <div className="card-content">
                      <div>
                        <p>{dashboard.waterTreatmentPlantProduction.pre}</p>
                      </div>
                      <div>
                        <span
                          className="card-value"
                          id="waterTreatmentPlantProductionValue"
                        >
                          {!dashboard.waterTreatmentPlantProduction.override && tmwa?.tprod ?
                            numberWithCommas(tmwa.tprod.toFixed(1))
                            : dashboard.waterTreatmentPlantProduction.value ? numberWithCommas(dashboard.waterTreatmentPlantProduction.value) : null}
                        </span>
                        <span className="card-sup">
                          {dashboard.waterTreatmentPlantProduction.units}
                        </span>
                      </div>
                      <div>
                        <p>
                          <span className="is-inline-block">
                            {dashboard.waterTreatmentPlantProduction.post.left}
                          </span>
                          &nbsp;&nbsp;&nbsp;
                          <span className="is-inline-block">
                            {dashboard.waterTreatmentPlantProduction.post.right}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              {/* Hydroelectric Production card */}
              <div className="column is-one-third-widescreen is-half">
                <Link
                  to="#dashboard"
                  className={`more-options-link`}
                  data-target="hydroelectricProductionBack"
                  onClick={() =>
                    this.showBackOfCard("hydroelectricProductionBack")
                  }
                  onKeyDown={() =>
                    this.showBackOfCard("hydroelectricProductionBack")
                  }
                  tabIndex={0}
                  aria-label="More information"
                >
                  <div className="card" id="hydroelectricProductionCard">
                    <div className="card-header">
                      <div className="icon card-header-icon">
                        <Lightning />
                      </div>
                      <h3 className="card-header-title">
                        {dashboard.hydroelectricProduction.heading}
                      </h3>
                      <div className="icon card-header-icon card-header-options">
                        <MoreOptions />
                      </div>
                    </div>
                    <div className="card-content">
                      <div>
                        <p className="mx-1">
                          {dashboard.hydroelectricProduction.pre}
                        </p>
                      </div>
                      <div style={{ marginTop: "-1rem" }}>
                        <span
                          className="card-value"
                          id="hydroelectricProductionValue"
                        >
                          {(!dashboard.hydroelectricProduction.override && tmwa?.tgen) ? numberWithCommas(
                            (tmwa.tgen).toFixed()
                          ) : dashboard.hydroelectricProduction.value ? numberWithCommas(
                            dashboard.hydroelectricProduction.value
                          ) : null}
                        </span>
                        <span className="card-sup">
                          {dashboard.hydroelectricProduction.units}
                        </span>
                      </div>
                      <div>
                        <p>
                          {dashboard.hydroelectricProduction.post.pre + " "}
                          <span id="hydroelectricProductionHouseholds">
                            {(!dashboard.hydroelectricProduction.override && tmwa?.tgen) ?
                              numberWithCommas(Math.round(tmwa.tgen * 0.52))
                              : dashboard.hydroelectricProduction.post.value ? numberWithCommas(
                                dashboard.hydroelectricProduction.post.value
                              ) : null}
                          </span>
                          {" " + dashboard.hydroelectricProduction.post.post}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            {/* ------------------------------------------------ BACKS OF CARDS ------------------------------------------------ */}
            <div className="dashboard-card-back" id="dashboardCardBack">
              {/* BACK waterYear card */}
              {/* TO DO: set frontmatter fields for data */}
              <div className="card card-back" id="waterYearBack">
                <div className="card-header">
                  <div className="icon card-header-icon">
                    <Snowflake />
                  </div>
                  <h3 id="waterYearBackHeading" className="card-header-title">
                    {dashboard.waterYear.heading}
                  </h3>
                  <div className="icon card-header-icon card-header-options">
                    <button
                      className={`close-link`}
                      data-target="dashboardCardContainer"
                      onClick={() => this.showFrontOfCards("waterYearBack")}
                      onKeyDown={() => this.showFrontOfCards("waterYearBack")}
                      tabIndex={0}
                      aria-label="Hide more information"
                    >
                      <Close />
                    </button>
                  </div>
                </div>
                <div className="card-content">
                  {dashboard.waterYear?.back?.pre && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: remark()
                          .use(recommended)
                          .use(remarkHtml)
                          .processSync(dashboard.waterYear.back.pre)
                          .toString(),
                      }}
                    />
                  )}
                  {dashboard.waterYear?.back?.caption ||
                    dashboard.waterYear?.back?.percentage1?.label ||
                    dashboard.waterYear?.back?.percentage1?.value ||
                    dashboard.waterYear?.back?.percentage2?.label ||
                    (dashboard.waterYear?.back?.percentage2?.value && (
                      <div>
                        <table style={{ width: "90%" }}>
                          {dashboard.waterYear?.back?.caption && (
                            <caption>
                              {dashboard.waterYear?.back?.caption}
                            </caption>
                          )}
                          <tbody>
                            <tr>
                              <td>
                                {dashboard.waterYear?.back?.percentage1?.label}
                              </td>
                              <td>
                                <span id="tahoeSnotel">
                                  {
                                    dashboard.waterYear?.back?.percentage1
                                      ?.value
                                  }
                                </span>{" "}
                                {dashboard.waterYear?.back?.units}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {dashboard.waterYear?.back?.percentage2?.label}
                              </td>
                              <td>
                                <span id="truckeeSnotel">
                                  {
                                    dashboard.waterYear?.back?.percentage2
                                      ?.value
                                  }
                                </span>{" "}
                                {dashboard.waterYear?.back?.units}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: remark()
                        .use(recommended)
                        .use(remarkHtml)
                        .processSync(dashboard.waterYear.back.post)
                        .toString(),
                    }}
                  />
                </div>
              </div>

              {/* BACK River Flows */}
              <div className="card card-back" id="riverflowBack">
                <div className="card-header">
                  <div className="icon card-header-icon">
                    <RiverFlow />
                  </div>
                  <h3 id="riverflowBackHeading" className="card-header-title">
                    {dashboard.riverflows.heading}
                  </h3>
                  <div className="icon card-header-icon card-header-options">
                    <div
                      className={`close-link`}
                      data-target="dashboardCardContainer"
                      onClick={() => this.showFrontOfCards("riverflowBack")}
                      onKeyDown={() => this.showFrontOfCards("riverflowBack")}
                      role="button"
                      tabIndex={0}
                      aria-label="Hide more information"
                    >
                      <Close />
                    </div>
                  </div>
                </div>
                <div className="card-content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: remark()
                        .use(recommended)
                        .use(remarkHtml)
                        .processSync(dashboard.riverflows.back.content)
                        .toString(),
                    }}
                  />
                </div>
              </div>

              {/* BACK Upstream Water Storage card */}
              <div className="card card-back" id="drinkingWaterStorageBack">
                <div className="card-header">
                  <div className="icon card-header-icon">
                    <WaterGlass />
                  </div>
                  <h3
                    id="drinkingWaterStorageBackHeading"
                    className="card-header-title"
                  >
                    {dashboard.upstreamWaterStorage.heading}
                  </h3>
                  <div className="icon card-header-icon card-header-options">
                    <div
                      className={`close-link`}
                      data-target="dashboardCardContainer"
                      onClick={() =>
                        this.showFrontOfCards("drinkingWaterStorageBack")
                      }
                      onKeyDown={() =>
                        this.showFrontOfCards("drinkingWaterStorageBack")
                      }
                      role="button"
                      tabIndex={0}
                      aria-label="Hide more information"
                    >
                      <Close />
                    </div>
                  </div>
                </div>
                <div className="card-content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: remark()
                        .use(recommended)
                        .use(remarkHtml)
                        .processSync(dashboard.upstreamWaterStorage.back.pre)
                        .toString(),
                    }}
                  />
                  <table>
                    {!dashboard.upstreamWaterStorage.override &&
                      waterStorage &&
                      typeof waterStorage.totalStorage !== "undefined" &&
                      waterStorage.totalStorage ? (
                      <tbody>
                        {waterStorage.reservoirs
                          .sort((a, b) => (a.Name > b.Name ? 1 : -1))
                          .map((reservoir) => (
                            <tr key={reservoir.Name}>
                              {
                                {
                                  Tahoe: (
                                    <td>
                                      {
                                        dashboard.upstreamWaterStorage.back
                                          .tahoe.label
                                      }
                                    </td>
                                  ),
                                  Boca: (
                                    <td>
                                      {
                                        dashboard.upstreamWaterStorage.back.boca
                                          .label
                                      }
                                    </td>
                                  ),
                                  Stampede: (
                                    <td>
                                      {
                                        dashboard.upstreamWaterStorage.back
                                          .stampede.label
                                      }
                                    </td>
                                  ),
                                  Independence: (
                                    <td>
                                      {
                                        dashboard.upstreamWaterStorage.back
                                          .independence.label
                                      }
                                    </td>
                                  ),
                                  Prosser: (
                                    <td>
                                      {
                                        dashboard.upstreamWaterStorage.back
                                          .prosser.label
                                      }
                                    </td>
                                  ),
                                  Donner: (
                                    <td>
                                      {
                                        dashboard.upstreamWaterStorage.back
                                          .donner.label
                                      }
                                    </td>
                                  ),
                                  default: <td>{reservoir.Name}</td>,
                                }[reservoir.Name]
                              }
                              <td>
                                {numberWithCommas(reservoir.Storage)}{" "}
                                {dashboard.upstreamWaterStorage.back.units}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>
                            {dashboard.upstreamWaterStorage.back.tahoe.label}
                          </td>
                          <td>
                            {numberWithCommas(
                              dashboard.upstreamWaterStorage.back.tahoe.value
                            )}{" "}
                            {dashboard.upstreamWaterStorage.back.units}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {dashboard.upstreamWaterStorage.back.donner.label}
                          </td>
                          <td>
                            {numberWithCommas(
                              dashboard.upstreamWaterStorage.back.donner.value
                            )}{" "}
                            {dashboard.upstreamWaterStorage.back.units}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {
                              dashboard.upstreamWaterStorage.back.independence
                                .label
                            }
                          </td>
                          <td>
                            {numberWithCommas(
                              dashboard.upstreamWaterStorage.back.independence
                                .value
                            )}{" "}
                            {dashboard.upstreamWaterStorage.back.units}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {dashboard.upstreamWaterStorage.back.prosser.label}
                          </td>
                          <td>
                            {numberWithCommas(
                              dashboard.upstreamWaterStorage.back.prosser.value
                            )}{" "}
                            {dashboard.upstreamWaterStorage.back.units}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {dashboard.upstreamWaterStorage.back.stampede.label}
                          </td>
                          <td>
                            {numberWithCommas(
                              dashboard.upstreamWaterStorage.back.stampede.value
                            )}{" "}
                            {dashboard.upstreamWaterStorage.back.units}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {dashboard.upstreamWaterStorage.back.boca.label}
                          </td>
                          <td>
                            {numberWithCommas(
                              dashboard.upstreamWaterStorage.back.boca.value
                            )}{" "}
                            {dashboard.upstreamWaterStorage.back.units}
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: remark()
                        .use(recommended)
                        .use(remarkHtml)
                        .processSync(dashboard.upstreamWaterStorage.back.post)
                        .toString(),
                    }}
                  />
                </div>
              </div>

              {/* BACK Lake Tahoe Level */}
              <div className="card card-back" id="lakeTahoeLevelBack">
                <div className="card-header">
                  <div className="icon card-header-icon">
                    <Boat />
                  </div>
                  <h3
                    className="card-header-title"
                    id="lakeTahoeLevelBackHeading"
                  >
                    {dashboard.lakeTahoeLevel.heading}
                  </h3>
                  <div className="icon card-header-icon card-header-options">
                    <div
                      className={`close-link`}
                      data-target="dashboardCardContainer"
                      onClick={() =>
                        this.showFrontOfCards("lakeTahoeLevelBack")
                      }
                      onKeyDown={() =>
                        this.showFrontOfCards("lakeTahoeLevelBack")
                      }
                      role="button"
                      tabIndex={0}
                      aria-label="Hide more information"
                    >
                      <Close />
                    </div>
                  </div>
                </div>
                <div className="card-content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: remark()
                        .use(recommended)
                        .use(remarkHtml)
                        .processSync(dashboard.lakeTahoeLevel.back.content)
                        .toString(),
                    }}
                  />
                </div>
              </div>

              {/* BACK Water Treatment Plant Production card */}
              <div
                className="card card-back"
                id="waterTreatmentPlantProductionBack"
              >
                <div className="card-header">
                  <div className="icon card-header-icon">
                    <WaterDrop />
                  </div>
                  <h3
                    id="waterTreatmentPlantProductionBackHeading"
                    className="card-header-title"
                  >
                    {dashboard.waterTreatmentPlantProduction.heading}
                  </h3>
                  <div className="icon card-header-icon card-header-options">
                    <div
                      className={`close-link`}
                      data-target="dashboardCardContainer"
                      onClick={() =>
                        this.showFrontOfCards(
                          "waterTreatmentPlantProductionBack"
                        )
                      }
                      onKeyDown={() =>
                        this.showFrontOfCards(
                          "waterTreatmentPlantProductionBack"
                        )
                      }
                      role="button"
                      tabIndex={0}
                      aria-label="Hide more information"
                    >
                      <Close />
                    </div>
                  </div>
                </div>
                <div className="card-content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: remark()
                        .use(recommended)
                        .use(remarkHtml)
                        .processSync(
                          dashboard.waterTreatmentPlantProduction.back.pre
                        )
                        .toString(),
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: remark()
                        .use(recommended)
                        .use(remarkHtml)
                        .processSync(
                          dashboard.waterTreatmentPlantProduction.back.post
                        )
                        .toString(),
                    }}
                  />
                </div>
              </div>

              {/* BACK Hydroelectric Production */}
              <div className="card card-back" id="hydroelectricProductionBack">
                <div className="card-header">
                  <div className="icon card-header-icon">
                    <Lightning />
                  </div>
                  <h3
                    id="hydroelectricProductionBackHeading"
                    className="card-header-title"
                  >
                    {dashboard.hydroelectricProduction.heading}
                  </h3>
                  <div className="icon card-header-icon card-header-options">
                    <div
                      className={`close-link`}
                      data-target="dashboardCardContainer"
                      onClick={() =>
                        this.showFrontOfCards("hydroelectricProductionBack")
                      }
                      onKeyDown={() =>
                        this.showFrontOfCards("hydroelectricProductionBack")
                      }
                      role="button"
                      tabIndex={0}
                      aria-label="Hide more information"
                    >
                      <Close />
                    </div>
                  </div>
                </div>
                <div className="card-content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: remark()
                        .use(recommended)
                        .use(remarkHtml)
                        .processSync(
                          dashboard.hydroelectricProduction.back.content
                        )
                        .toString(),
                    }}
                  />
                </div>
              </div>
            </div>{" "}
            {/* Dashboard card back */}
          </div>
        </div>
      </section>
    );
  }
};

Dashboard.propTypes = {
  waterStorage: PropTypes.shape({
    totalStorage: PropTypes.number,
    reservoirs: PropTypes.array,
  }),
  riverflow: PropTypes.shape({
    reno: PropTypes.number,
    farad: PropTypes.number,
  }),
  lakeTahoe: PropTypes.shape({
    level: PropTypes.number,
  }),
  tmwa: PropTypes.shape({
    tgen: PropTypes.number,
  }),
  dashboard: PropTypes.shape({
    heading: PropTypes.string,
    waterYear: PropTypes.shape({
      heading: PropTypes.string,
      pre: PropTypes.string,
      date: PropTypes.string,
      percentage1: PropTypes.object,
      percentage2: PropTypes.object,
      post: PropTypes.string,
      override: PropTypes.bool,
      back: PropTypes.shape({
        pre: PropTypes.string,
        caption: PropTypes.string,
        post: PropTypes.string,
        percentage1: PropTypes.object,
        percentage2: PropTypes.object,
      }),
    }),
    riverflows: PropTypes.shape({
      heading: PropTypes.string,
      reno: PropTypes.object,
      farad: PropTypes.object,
      back: PropTypes.shape({
        content: PropTypes.string,
      }),
      override: PropTypes.bool,
    }),
    upstreamWaterStorage: PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.number,
      units: PropTypes.string,
      label: PropTypes.object,
      override: PropTypes.bool,
      back: PropTypes.object,
    }),
    lakeTahoeLevel: PropTypes.shape({
      heading: PropTypes.string,
      min: PropTypes.number,
      max: PropTypes.number,
      percentage: PropTypes.number,
      value: PropTypes.number,
      label: PropTypes.string,
      back: PropTypes.shape({
        content: PropTypes.string,
      }),
      override: PropTypes.bool,
    }),
    waterTreatmentPlantProduction: PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.number,
      units: PropTypes.string,
      pre: PropTypes.string,
      post: PropTypes.object,
      back: PropTypes.object,
      override: PropTypes.bool,
    }),
    hydroelectricProduction: PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.number,
      units: PropTypes.string,
      label: PropTypes.object,
      override: PropTypes.bool,
    }),
  }),
};

export default Dashboard;
